.App {
  font-family: sans-serif;
  /* text-align: center; */
}

.Outer {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100vh;
}

.Preview_image {
  margin-left: auto;
  margin-right: auto;

  width: 50%;
  display: block;
}

.OuterDiv {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0px;
}

.Canvas {
  height: 100%;
  margin-left: 50%;
  transform: translateX(-50%);
}

body {
  margin: 0;
  background-color: #fff;
  font-family: Monospace;
  font-size: 13px;
  line-height: 24px;
  overscroll-behavior: none;
  overflow: hidden; /* Hide scrollbars */
}

.swiper {
  position: absolute;
  top: 85vh;
  width: 100vh;
}

.swiper-slide-active {
  width: calc(15.7vw + 34px) !important;
  max-width: 147.03px;
}
