body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  width: 200px
}

.fullpage-wrapper{
  position: relative;
  z-index: 1
}

#canvas-container {
  position: relative;
  z-index: -1;
      /* width: 50vw !important;
      left: 25% !important; */
}
/* canvas {
  z-index: -999;
}
.section {
  position: relative;
  z-index: 3;
} */

body {
  padding: 20px;
}

.button {
  border: 1px solid black;
  width: 100px;
  height: 20px;
  text-align: center;
}

.circle,
.content {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 2s ease-in-out;
  -ms-transition: all 2s ease-in-out;
  transition: all 2s ease-in-out;
}

.circle {
    background: rgb(132 93 93 / 25%);
    border-radius: 100%;
    color: white;
    text-align: center;
    font-family: sans-serif;
    padding: 20px;
    overflow: hidden;
    width: 5vw;
    height: 5vw;
    margin-top: 14vw;
    margin-left: 17.5vw;
    position: absolute;
    top: 10%;
    left: 28.5vw;
}

.circle.show {
  width: 40vw;
  height: 40vw;
  margin: 0;
}

@media only screen and (max-width: 600px) {
  .circle {
    background: rgb(132 93 93 / 50%);
    border-radius: 100%;
    color: white;
    text-align: center;
    font-family: sans-serif;
    padding: 20px;
    overflow: hidden;
    width: 5vw;
    height: 5vw;
    margin-top: 14vw;
    margin-left: 17.5vw;
    position: absolute;
    top: 5%;
    left: 15vw;
  }
  
  .circle.show {
    width: 60vw;
    height: 60vw;
    margin: 0;
  }
}

.content {
  line-height: 100px;
  position: absolute;
  width: 100px;
}

.show .content {
  line-height: 400px;
  width: 400px;
}

.MuiPaper-root {
  background-color: #4c4c4c !important;
}
.MuiButton-outlinedPrimary{
    width: 170px;
    margin: 20px !important;
    color: #4c4c4c !important;
    border-color: #4c4c4c !important;
  }

.MuiAppBar-positionStatic {
  position: sticky !important;
  z-index: 1000;
}