.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.css-q8hpuo-MuiFormControl-root {
  width: 250px !important;
}

.swatch-button {
  all: unset;
  border-radius: 100%;
  border: 1px solid rgb(225, 225, 225);
  width: 40px;
  height: 40px;
  text-align: center;
  margin: 10px;
  box-shadow: 0px 1px 3px #666666;
  cursor: pointer;
  /* background-color: #9bd0ff; */
}

.next-box {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.fp-watermark {
  display: none;
}